import React from 'react';
import { Grid, Box, Modal, Typography, Button } from '@mui/material';
import { Paper, Card, CardHeader, CardContent, Divider } from '@mui/material';
import { InfoOutlined, ErrorOutline, WarningAmber, CheckCircleOutline, NoBackpackSharp } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@mui/styles';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
var bgColor = null;
var icon = null;

const ApiStatusModal = (props) => {
  const [open, setOpen] = React.useState(true);
  //const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false);
    props.setMessage ? props.setMessage() : '';
  };
  const theme = useTheme();

  switch (props.status) {
    case 'Error':
      icon = <ErrorOutline sx={{ mb: -0.625 }} />;
      bgColor = theme.palette.error.main;
      break;
    case 'Info':
      icon = (
        <FontAwesomeIcon
          icon={faCircleInfo}
          style={{
            height: '20px',
            width: '20px',
            // marginTop: '-2px',
            marginLeft: '2px',
            // color: '#87CEFA',
          }}
        />
      );
      bgColor = theme.palette.mode == 'light' ? theme.palette.primary[200] : theme.palette.primary.dark;
      break;
    case 'Warning':
      icon = <WarningAmber sx={{ mb: -0.625 }} />;
      bgColor = theme.palette.warning.dark;
      break;
  }

  return (
    <div>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box display="flex">
          <Paper
            elevation={4}
            sx={{
              position: 'absolute',
              top: '15%',
              left: { xs: '9%', sm: '30%', md: '40%' },
              zIndex: 3,
              m: 'auto',
              mt: 22,
              alignContent: 'center',
              height: 100,
              width: 320,
            }}>
            <Card
              sx={{
                border: 'none',
              }}>
              <CardHeader
                sx={{
                  padding: '8px',
                  background: bgColor,
                  '& .MuiCardHeader-action': { mr: 0 },
                }}
                title={
                  <div>
                    {icon} {props.status}
                    <span style={{ paddingLeft: '205px', cursor: 'pointer' }}>
                      <CloseIcon onClick={handleClose} />
                    </span>
                  </div>
                }></CardHeader>
              <CardContent sx={{ p: 2.5, pt: 1, paddingBottom: '17px !important' }}>
                <Grid sx={{ pt: 1 }} item xs={12} container justifyContent="center">
                  {props.statusMessage}
                </Grid>
              </CardContent>
            </Card>
          </Paper>
        </Box>
      </Modal>
    </div>
  );
};

export default ApiStatusModal;
