import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

// project imports
import MainLayout from 'layout/OtherLayout/OtherLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from '../services/AuthGaurd';
import { Circle } from '@mui/icons-material';
import { element } from 'prop-types';
import EquipmentUtilizationConfiguration from 'views/VDMS/EquipmentUtilizationConfiguration';

// sample page routing
const LiveDataMonitoring = Loadable(lazy(() => import('views/LiveDataMonitoring/index')));
const VoyageAnalysis = Loadable(lazy(() => import('views/VoyageAnalysis')));

const HPPerformance = Loadable(lazy(() => import('views/HPPerformance')));
const MEPerformance = Loadable(lazy(() => import('views/MEPerfromance')));
const FleetAlarmDashboard = Loadable(lazy(() => import('views/FleetAlarmDashboard')));
const CarbonIndicator = Loadable(lazy(() => import('views/CarbonIndicator')));
const DynamicAnalysis = Loadable(lazy(() => import('views/DynamicAnalysis')));
const ConsumptionReport = Loadable(lazy(() => import('views/Reports/ConsumptionReport')));
const EquipmentUtilization = Loadable(lazy(() => import('views/EquipmentUtilization')));
const EquipmentUtilizationDetails = Loadable(lazy(() => import('views/EquipmentUtilizationDetails')));
const EUMRV = Loadable(lazy(() => import('views/EUMRV')));
const EUETS = Loadable(lazy(() => import('views/EU-ETS')));
const FleetAlarams = Loadable(lazy(() => import('views/FleetAlarams')));
const AlarmOverview = Loadable(lazy(() => import('views/AlarmOverview')));
const AlarmMaster = Loadable(lazy(() => import('views/AlarmMaster')));
const LubeDashBoard = Loadable(lazy(() => import('views/LubeOilDashboard')));
const VesselParticulars = Loadable(lazy(() => import('views/VDMS/VesselParticular')));
const VesselRegister = Loadable(lazy(() => import('views/VDMS/VesselRegister')));
const SeaTrial = Loadable(lazy(() => import('views/VDMS/SeaTrial')));
const ShopTest = Loadable(lazy(() => import('views/VDMS/ShopTest')));
const ModelTest = Loadable(lazy(() => import('views/VDMS/ModelTest')));
const Hydrostatic = Loadable(lazy(() => import('views/VDMS/Hydrostatic')));
const CMS = Loadable(lazy(() => import('views/VDMS/CMS')));
const DesignProfile = Loadable(lazy(() => import('views/VDMS/DesignProfile')));
const RoleAccessManagement = Loadable(lazy(() => import('views/RoleAccessManagement')));
const ConsumptionKPI = Loadable(lazy(() => import('views/ConsumptionKPI')));
const DetailConsumptionKPI = Loadable(lazy(() => import('views/ConsumptinKpiDetail')));
const PortOptimisation = Loadable(lazy(() => import('views/PortOptimisation')));
const MachineryOptimisation = Loadable(lazy(() => import('views/MachineryOptimisation')));
const BoilerOptimisation = Loadable(lazy(() => import('views/BoilerOptimisation')));
const AEOptimisation = Loadable(lazy(() => import('views/AEOptimisation')));
const VesselLicenseManagement = Loadable(lazy(() => import('views/VesselLicenseManagement')));
const Ports = Loadable(lazy(() => import('views/Ports')));
const EquipmentAlarm = Loadable(lazy(() => import('views/EquipmentAlarm')));
const CIISimulator = Loadable(lazy(() => import('views/CIISimulator')));
const FleetUserAssociation = Loadable(lazy(() => import('views/FleetUserAssociation')));
// ==============================|| MAIN ROUTING ||============================== //

const KPIRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/live-data-monitoring',
      element: <LiveDataMonitoring />,
    },
    {
      path: '/voyage-analysis',
      element: <VoyageAnalysis />,
    },
    {
      path: '/equipment-utilization',
      element: <EquipmentUtilization />,
    },
    {
      path: '/equipmentstatistics-details',
      element: <EquipmentUtilizationDetails />,
    },
    {
      path: '/hp-perfromance',
      element: <HPPerformance />,
    },
    {
      path: '/me-perfromance',
      element: <MEPerformance />,
    },
    {
      path: '/fleet-alarm-dashboard',
      element: <FleetAlarmDashboard />,
    },
    {
      path: '/consumption-kpi',
      element: <ConsumptionKPI />,
    },
    {
      path: '/detail-consumption-kpi',
      element: <DetailConsumptionKPI />,
    },
    {
      path: '/port-optimisation',
      element: <PortOptimisation />,
    },
    {
      path: '/machinery-optimisation',
      element: <MachineryOptimisation />,
    },
    {
      path: '/boiler-optimisation',
      element: <BoilerOptimisation />,
    },
    {
      path: '/ae-optimisation',
      element: <AEOptimisation />,
    },
    {
      path: '/carbon-indicator',
      element: <CarbonIndicator />,
    },
    {
      path: '/cii-simulator',
      element: <CIISimulator />,
    },
    {
      path: '/dynamic-analysis',
      element: <DynamicAnalysis />,
    },
    {
      path: '/consumption-report',
      element: <ConsumptionReport />,
    },
    {
      path: '/eu-mrv',
      element: <EUMRV />,
    },
    {
      path: '/eu-ets',
      element: <EUETS />,
    },
    {
      path: '/fleet-alarm-overview',
      element: <FleetAlarams />,
    },
    {
      path: '/alarm-overview',
      element: <AlarmOverview />,
    },
    {
      path: '/equipment-alarm',
      element: <EquipmentAlarm />,
    },
    {
      path: '/alarm-master',
      element: <AlarmMaster />,
    },
    {
      path: '/lubeoil-dashboard',
      element: <LubeDashBoard />,
    },
    {
      path: '/vessel-register',
      element: <VesselRegister />,
    },
    {
      path: '/vessel-particulars',
      element: <VesselParticulars />,
    },
    {
      path: '/sea-trial',
      element: <SeaTrial />,
    },
    {
      path: '/shop-test',
      element: <ShopTest />,
    },
    {
      path: '/model-test',
      element: <ModelTest />,
    },
    {
      path: '/hydrostatics',
      element: <Hydrostatic />,
    },
    {
      path: '/design-profile',
      element: <DesignProfile />,
    },
    {
      path: '/cms',
      element: <CMS />,
    },
    {
      path: '/role-access-management',
      element: <RoleAccessManagement />,
    },
    {
      path: '/vessel-license-management',
      element: <VesselLicenseManagement />,
    },
    {
      path: '/ports',
      element: <Ports />,
    },
    { path: '/equip-utilization-config', element: <EquipmentUtilizationConfiguration /> },
    {
      path: '/vessel-fleet-user-assocoation',
      element: <FleetUserAssociation />,
    },
  ],
};

export default KPIRoutes;
